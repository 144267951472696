.createWrapper{
    display: flex;
    justify-content: center;
    margin: 20px 0;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
}

.createBtn{
    background-color: #2b90c3; 
    border: none; 
    color: white; 
    padding: 15px 32px; 
    text-align: center; 
    text-decoration: none; 
    display: inline-block; 
    font-size: 16px; 
    cursor: pointer; 
    border-radius: 4px; 
    transition-duration: 0.4s; 
    margin: 61.4px 0;
    
}

.createBtn:hover {
    background-color: #2b90c3c2;
}

.fieldsContainer{
    width: 300px;
}

.fieldsContainer input {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #f2f2f2;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
  }

.fieldsContainer input:focus {
    outline: none;
    box-shadow: 0px 0px 5px #a5a5a5;
  }

.inputIcons{
    display: flex;
    justify-content: space-between;
    font-size: 26px;
    padding: 0 20px;

}

.icon{
    color: #0b0b0b;
    cursor: pointer;
}

.icon:hover{
    opacity: 0.8;
}

.icon:active{
    opacity: 0.6;
}

.valid{
    box-shadow: 0 0 4px 1px red;
}