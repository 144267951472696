.wrapper{
    display: flex;
    justify-content: center;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    padding: 0 20px;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
}